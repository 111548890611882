import GoogleService from "@/services/marketing-services/GoogleService";
import GoogleAnalyticsService from "@/services/marketing-services/GoogleAnalyticsService";
import MarketingService from "@/services/MarketingService";

describe("MarketingService", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });
  describe("google", () => {
    it("should return google service", () => {
      // Given
      // When
      // Then
      expect(MarketingService.google()).toBeInstanceOf(GoogleService);
    });
  });
  describe("analytics", () => {
    it("should return analytics service", () => {
      // Given
      // When
      // Then
      expect(MarketingService.analytics()).toBeInstanceOf(
        GoogleAnalyticsService
      );
    });
  });
});
